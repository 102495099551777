<template>
  <div>
    <div class="back-color">
      <NavBar class="nav-nar">
        <div slot="title">信息修改</div>
      </NavBar>
      <div class="mobile-and-password">
        <!-- 使用v-if判定是否需要手机验证 -->
        <van-field
          v-if="isEdit"
          v-model="number"
          :border="false"
          type="number"
          label="手机号"
          placeholder="请输入手机号"
          disabled
        />
        <van-field
          v-else
          v-model="number"
          :border="false"
          type="number"
          label="手机号"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="sms"
          center
          clearable
          label="验证码"
          type="number"
          placeholder="请输入验证码"
        >
          <template #button>
            <van-button
              v-if="time <= 0"
              plain
              size="small"
              type="danger"
              @click="sendCode"
            >
              发送验证码
            </van-button>
            <!-- 验证码计时器 -->
            <div class="timer" v-else>
              <van-count-down :time="time" format="ss">
                <template #default="timeData">
                  <span class="block">{{ timeData.seconds }}</span>
                  <span class="colon">秒</span>
                </template>
              </van-count-down>
            </div>
          </template>
        </van-field>
        <van-field
          v-model="newPassword"
          :border="false"
          :type="isLookSet ? 'text' : 'password'"
          label="新密码"
          placeholder="输入新密码"
          @input="verification"
        >
          <template #right-icon>
            <div>
              <van-icon
                :name="isLookSet ? 'eye-o' : 'closed-eye'"
                @click="setPassword"
              />
            </div>
          </template>
        </van-field>
        <van-field
          v-model="okPassword"
          :border="false"
          :type="isLookOk ? 'text' : 'password'"
          label="确认密码"
          placeholder="确认密码"
          @input="verification"
        >
          <template #right-icon>
            <div>
              <van-icon
                :name="isLookOk ? 'eye-o' : 'closed-eye'"
                @click="confirmPassword"
              />
            </div>
          </template>
        </van-field>
      </div>
      <div class="save-button">
        <van-button round block type="danger" @click="updatePayPass"
          >保存</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import { Toast } from "vant";
import { commonSend } from "@/api/common";
import { setPayPass, getMemberInfo } from "@/api/member";

export default {
  name: "",
  components: {
    NavBar,
  },
  data() {
    return {
      number: "",
      newPassword: "", //新密码
      okPassword: "", //确认密码
      sms: "",
      showBtn: true,
      time: 0,
      isLookSet: false,
      isLookOk: false,
      isEdit: false,
    };
  },
  mounted() {
    this.getMemberInfo();
  },
  methods: {
    setPassword() {
      // 设置密码 查看
      this.isLookSet = !this.isLookSet;
    },
    confirmPassword() {
      // 确认密码 查看
      this.isLookOk = !this.isLookOk;
    },
    verification(value) {
      if (isNaN(value)) {
        this.newPassword = "";
        this.okPassword = "";
      }
      if (this.newPassword.length > 6) {
        this.newPassword = this.newPassword.slice(
          0,
          this.newPassword.length - 1
        );
      }
      if (this.okPassword.length > 6) {
        this.okPassword = this.okPassword.slice(0, this.okPassword.length - 1);
      }
    },
    async sendCode() {
      if (this.number == "") {
        Toast.fail("请输入手机号码");
        return false;
      }
      const res = await commonSend({
        phone: this.number,
        send_type: "set_pay_pass",
      });
      if (res.code * 1 == 1) {
        this.time = 60000;
        let _that = this;
        setTimeout(() => {
          _that.time = 0;
        }, 59900);
      } else {
        Toast.fail(res.msg);
      }
    },
    async updatePayPass() {
      if (this.newPassword == "") {
        Toast.fail("请输入新密码");
        return false;
      }
      if (this.okPassword == "") {
        Toast.fail("请输入确认密码");
        return false;
      }
      if (this.code == "") {
        Toast.fail("请输入验证码");
        return false;
      }
      if (this.newPassword.length !== 6 || this.okPassword.length !== 6) {
        Toast.fail("请输入6位数密码");
        this.newPassword = "";
        this.okPassword = "";
        return;
      } else if (this.newPassword !== this.okPassword) {
        Toast.fail("两次密码不一致");
        this.newPassword = "";
        this.okPassword = "";
        return;
      }
      const ret = await setPayPass({
        pay_pass: this.newPassword,
        pass_two: this.okPassword,
        phone: this.number,
        code: this.sms,
      });
      if (ret.code * 1 === 1) {
        Toast.success("支付密码修改成功");
        const that = this;
        setTimeout(function () {
          that.$router.go(-1);
        }, 2000);
      } else {
        Toast.fail(ret.msg);
      }
    },
    onFinish() {
      this.showBtn = true;
    },
    async getMemberInfo() {
      const res = await getMemberInfo();
      this.number = res.data.phone_num;
      if (this.number !== "") {
        this.isEdit = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-nar {
  background-color: #fff;
}
.back-color {
  padding: 0 12px;
  background-color: #fff;
  height: 100vh;
}
.mobile-and-password {
  margin-top: 54px;
}
.nav-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  font-size: 20px;
  border-bottom: 1px solid #ededed;
}

.van-cell__value {
  font-size: 16px;
  font-weight: 600;
}
.van-cell--clickable {
  padding: 10px 0;
}
.mobile-flex {
  display: flex;
  justify-content: space-between;
  .mobile {
    font-weight: normal;
    color: #6f6f6f;
  }
}
.van-button--danger {
  border: none;
}
.van-button__content {
  font-size: 17px;
}
.van-field {
  display: flex;
  align-items: center;
  padding: 10px 0;
  height: 54px;
  border-bottom: 1px solid #ededed;
}
/deep/.van-field__control {
  font-size: 17px;
}
/deep/.van-cell__title {
  font-size: 17px;
  color: #000;
}
.save-button {
  margin-top: 20px;
  padding: 0 12px;
}
.timer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 104px;
  height: 52px;
}
.van-count-down {
  font-size: 16px;
}
</style>
